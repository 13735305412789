import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdMenu, MdOutlineClose } from "react-icons/md";
import Button from "../Button";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  // Highlight active links
  const isActive = (route) => location.pathname.includes(route);

  // Navigate and scroll to a specific section
  const handleNavigate = (path, sectionId) => {
    navigate(path);
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  // Update header background on scroll
  useEffect(() => {
    const handleScroll = () => setHasScrolled(window.scrollY > 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header>
      <nav
        className={`tw-w-full position-fixed  tw-bg-[rgba(103,96,96,0.17)]  tw-z-20 ${
          hasScrolled ? " tw-top-0 tw-backdrop-blur" : ""
        } tw-bg-[black] sm:tw-relative md:tw-fixed`}
      >
        <div className="container tw-mx-auto tw-flex tw-items-center tw-justify-between tw-h-[4rem] md:tw-h-[6rem]">
          {/* Logo */}
          <div>
            <Link to="/" style={{textDecoration:"none"}}>
              <img
                src={require("../../assets/images/logo1.png")}
                alt="Logo"
                className="tw-object-contain tw-w-[50px] md:tw-w-[80px]"
              />
            </Link>
          </div>
          <div className="mobile-view">
            <Button
              onClick={() => open()}
              className=""
              label={
                isConnected
                  ? `${address.slice(0, 4)}....${address.slice(-4)}`
                  : "Connect Wallet"
              }
            />
          </div>
          {/* Desktop Menu */}
          <ul className="lg:tw-flex tw-hidden tw-items-center tw-gap-8 tw-font-[Poppins]">
            {[
              { label: "Home", path: "/", section: "" },
              { label: "About Us", path: "/", section: "aboutSection" },
              { label: "Tokenomics", path: "/", section: "tokenSection" },
              { label: "Road Map", path: "/", section: "roadSection" },
              { label: "Team", path: "/", section: "teamSection" },
              { label: "Staking", path: "/staking" },
              {
                label: "MLM",
                path: "https://mlm.apesrun.com/",
                external: true,
              },
            ].map((item, index) => (
              <li key={index}>
                {item.external ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`tw-text-white ${
                      isActive(item.path) ? "active" : ""
                    }`}
                  >
                    {item.label}
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    onClick={() =>
                      item.section && handleNavigate(item.path, item.section)
                    }
                    className={`tw-text-white ${
                      isActive(item.path) ? "active" : ""
                    }`}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>

          {/* Desktop Connect Wallet Button */}
          <div className="md:tw-block tw-hidden">
            <Button
              onClick={() => open()}
              className="tw-br-[30px]"
              label={
                isConnected
                  ? `${address.slice(0, 4)}....${address.slice(-4)}`
                  : "Connect Wallet"
              }
            />
          </div>

          {/* Mobile Menu Toggle */}
          <div
            className="tw-text-3xl lg:tw-hidden tw-pt-2 tw-z-50"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? (
              <MdOutlineClose color="white" />
            ) : (
              <MdMenu color="white" />
            )}
          </div>

          {/* Mobile Menu */}
          <div
            className={`lg:tw-hidden tw-fixed tw-w-full tw-top-0 tw-h-[100vh] tw-overflow-y-auto tw-bg-black tw-py-10 tw-shadow-xl tw-duration-500 ${
              isMobileMenuOpen ? "tw-left-0" : "tw-left-[-100%]"
            }`}
          >
            <div className="tw-pb-5 tw-px-8">
              <img
                src={require("../../assets/images/logo1.png")}
                alt="Logo"
                width={80}
                className="tw-object-contain"
              />
            </div>

            <ul className="tw-p-0 tw-relative tw-px-9 tw-pt-3 tw-border-t">
              {[
                { label: "Home", path: "/", section: "" },
                { label: "About Us", path: "/", section: "aboutSection" },
                { label: "Tokenomics", path: "/", section: "tokenSection" },
                { label: "Road Map", path: "/", section: "roadSection" },
                { label: "Team", path: "/", section: "teamSection" },
                { label: "Staking", path: "/staking" },
                {
                  label: "MLM",
                  path: "https://mlm.apesrun.com/",
                  external: true,
                },
              ].map((item, index) => (
                <li key={index}>
                  {item.external ? (
                    <a
                      href={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-text-white"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      onClick={() =>
                        item.section &&
                        setMobileMenuOpen(false) &&
                        handleNavigate(item.path, item.section)
                      }
                      className="tw-text-white"
                    >
                      {item.label}
                    </Link>
                  )}
                </li>
              ))}
              <li className="tw-pt-5">
                <Button
                  onClick={() => open()}
                  className=""
                  label={
                    isConnected
                      ? `${address.slice(0, 4)}....${address.slice(-4)}`
                      : "Connect Wallet"
                  }
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
