import React from "react";
import pietoken from '../../assets/images/piechart.svg'

const Tokenomics = () => {
  return (
    <div id="tokenSection" className="tw-pb-20 ">
      <div className='token-div'>
        <h4 className="topic-heading">Tokenomics</h4>
      </div>

      <div className="container ">
        <div className="row">
          <div className="col-md-12 df-jsc-ac">
            <img src={pietoken} className=" tw-w-fill" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
