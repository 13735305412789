import React from "react";
import Button from "../Button";
import { FaArrowRight } from "react-icons/fa6";
import VideoSlider from "../videoSlider";
import VisionMission from "../Common/Vision Mission/VisionMission";

// import EBM_Avenue from '../EBM_avenue'
const About = (props) => {
  const openPdfInNewTab = () => {
    const pdfUrl = require("../../assets/images/whitepaper.pdf");
    window.open(pdfUrl, "_blank");
  };

  return (
    <div
      id="aboutSection"
      className=" tw-bg-[] tw-relative tw-pb-20  tw-w-full tw-h-auto">
      <div className="container">
        <VisionMission props={props} />
      </div>
    </div>
  );
};

export default About;
