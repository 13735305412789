import React from "react";
// import footerborder from '../assets/images/footerborder.svg'
import logo from "../../assets/images/apesrunlogo.svg";
import twit from "../../assets/images/twit.svg";
import tele from "../../assets/images/tele.svg";
import insta from "../../assets/images/insta.svg";
import youtube from "../../assets/images/youtube.svg";
import jok from "../../assets/images/jok.svg";
import discord from "../../assets/images/discord.svg";
import tiktok from "../../assets/images/tiktok.svg";
import "../footer/footer.scss";
import Button from "../Button";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Link } from "react-router-dom";
const Footer = () => {
  const { isConnected, address } = useAccount();
  const { open, close } = useWeb3Modal();
  return (
    <div>
      {/* <div className=" tw-overflow-x-hidden ">
        <div className="container tw-py-10">
          <div className="row tw-items-center"> */}
      <div className="footer">
        <div className="footer-content">
          <div className="footer-img-main-div">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="desktop-view">
                    <div className="main-social-div">
                      <div>
                        <img
                          src={logo}
                          width={72}
                          height={74}
                          alt="apesrunlogo"
                          title="this is the main logo for apesrun"
                        />
                      </div>
                      <div className="social-div">
                        <div>
                          <p className="follow">Follow us</p>
                        </div>
                        <a target="blank" href={"https://x.com/ApesRun_"}>
                          <div>
                            <img
                              src={twit}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a target="blank" href="https://t.me/apesrun_community">
                          <div>
                            <img
                              src={tele}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a
                          target="blank"
                          href="https://www.instagram.com/apesrun_/">
                          <div>
                            <img
                              src={insta}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a
                          target="blank"
                          href="https://www.youtube.com/@ApesRun">
                          <div>
                            <img
                              src={youtube}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        {/* <div>
                          <img
                            src={jok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={discord}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={tiktok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div> */}
                      </div>

                      <div>
                        <Button
                          className={"tw-br-[30px]"}
                          onClick={() => open()}
                          label={
                            !isConnected
                              ? "Connect Wallet"
                              : address.slice(0, 4) +
                                "...." +
                                address.slice(39, 42)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mobile-view">
                    <div className="main-social-div">
                      <div className="mob-footer mt-3">
                        <div>
                          <img
                            src={logo}
                            width={72}
                            height={74}
                            alt="apesrunlogo"
                            title="this is the main logo for apesrun"
                          />
                        </div>
                        <div>
                          <Button
                            className={"tw-br-[30px]"}
                            onClick={() => open()}
                            label={
                              !isConnected
                                ? "Connect Wallet"
                                : address.slice(0, 4) +
                                  "...." +
                                  address.slice(39, 42)
                            }
                          />
                        </div>
                      </div>

                      <div className="social-div mt-3 mb-2">
                        <div>
                          <p className="follow">Follow us</p>
                        </div>
                        <a target="blank" href={"https://x.com/ApesRun_"}>
                          <div>
                            <img
                              src={twit}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a target="blank" href="https://t.me/apesrun_community">
                          <div>
                            <img
                              src={tele}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a
                          target="blank"
                          href="https://www.instagram.com/apesrun_/">
                          <div>
                            <img
                              src={insta}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        <a
                          target="blank"
                          href="https://www.youtube.com/@ApesRun">
                          <div>
                            <img
                              src={youtube}
                              width={41}
                              height={41}
                              alt="social-media"
                            />
                          </div>
                        </a>
                        {/* <div>
                          <img
                            src={jok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={discord}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div>
                        <div>
                          <img
                            src={tiktok}
                            width={41}
                            height={41}
                            alt="social-media"
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="copy-right-div">
                    <p>© Apesrun2024</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //     </div>
    //   </div>

    // </div>
  );
};

export default Footer;
