import React from "react";
import Button from "../Button";
import roadmap from '../../assets/images/roadmap.svg'
const RoadMap = () => {
  return (
    <div id="roadSection" className="  container road-map-bg  md-tw-py-20 tw-py-10 tw-my-10 md-tw-my-20">
      <div className="  sm:tw-pb-0 ">
        <div className=" tw-text-center">
        <div className='token-div'>
        <h4 className="topic-heading md-tw-pt-10">Road Map</h4>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 df-jsc-ac">
            <img src={roadmap} className=" tw-w-fill" />
          </div>
        </div>
      </div>
</div>
      </div>
    </div>
  );
};



export default RoadMap;
