import React from "react";
import { Link } from "react-router-dom";
import OurNFT from "../Common/OurNFT/OurNFT";
const Team = () => {
  return (
    <div
      id="teamSection"
    >
      <div className="container tw-py-16 tw-text-center">
       
       
      <OurNFT/>

      </div>

    </div>
  );
};

const teamMembers = [


  {
    name: "Dr. Jesus Acantilado",
    role: "Founder | Medical Director",
    image: require("../../assets/images/nft13.PNG"),
    links: [
      { url: "", icon: "ic_sharp-discord.png", alt: "Discord" },
      { url: "", icon: "Symbol.png", alt: "Symbol" },
      { url: "", icon: "iconoir_telegram.png", alt: "Telegram" },
    ],
  },
  {
    name: "Ty JavellanA, CPA",
    role: "Finance & Investment",
    image: require("../../assets/images/nft.PNG"),
    links: [
      { url: "", icon: "ic_sharp-discord.png", alt: "Discord" },
      { url: "", icon: "Symbol.png", alt: "Symbol" },
      { url: "", icon: "iconoir_telegram.png", alt: "Telegram" },
    ],
  },
  {
    name: "Jose Cliff Romos",
    role: "Strategy & Innovation",
    image: require("../../assets/images/nft11.PNG"),
    links: [
      { url: "", icon: "ic_sharp-discord.png", alt: "Discord" },
      { url: "", icon: "Symbol.png", alt: "Symbol" },
      { url: "", icon: "iconoir_telegram.png", alt: "Telegram" },
    ],
  },
];

export default Team;
