import React from "react";

const Button = ({
  type,
  label,
  disabled,
  className,
  onClick,
  Icons,
  rIcons,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`tw-px-[12px] md:tw-px-[18px] tw-text-center tw-rounded-[30px] tw-py-[6px] md:tw-py-[11px] tw-font-Clash Display   tw-flex tw-items-center tw-justify-center tw-gap-1 tw-bg-[#2F80ED] tw-text-white ${className}`}
      disabled={disabled}>
      {Icons} {label} {rIcons}
    </button>
  );
};

export default Button;
