import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules"; // Correct import for Navigation
import "swiper/css"; // Base Swiper styles
import "swiper/css/navigation"; // Navigation module styles
import "./MobileSwiper.scss"; // Your custom styles

import IdeaBulb from "../../../assets/images/monkey1.svg";
import Monkey1 from "../../../assets/images/run1.svg";
import Monkey2 from "../../../assets/images/run2.svg";
import Monkey3 from "../../../assets/images/nft2.svg";
import Monkey4 from "../../../assets/images/nft3.svg";
import swiperleft from "../../../assets/images/swiperleft.svg";
import swiperright from "../../../assets/images/swiperright.svg";

function MobileSwiper() {
  const cvcard = [
    {
      title: "Fun and Engaging",
      content: "Say goodbye to boring earn models. With Apes Run, every shake brings you closer to exciting rewards.",
      img: IdeaBulb,
    },
    {
      title: "Instant Gratification",
      content: "Earn points or tokens in real-time and redeem them for real-world rewards or trade them within our ecosystem.",
      img: Monkey1,
    },
    {
      title: "Easy Access",
      content: "All you need is a smartphone and a Telegram account. Join the bot and start earning without any hassle.",
      img: Monkey2,
    },
    {
      title: "Community and Competition",
      content: "Engage in daily challenges, climb leaderboards, and share your progress with friends.",
      img: Monkey3,
    },
    {
      title: "Secure and Fair",
      content: "Advanced motion-sensing technology ensures accurate tracking, fair rewards, and a level playing field for everyone.",
      img: Monkey4,
    },
  ];

  return (
    <div className="slider-container position-relative">
      {/* Swiper Component */}
      <Swiper
        modules={[Navigation]} // Add the Navigation module
        slidesPerView={3}
        spaceBetween={10}
        loop={true}
        // centeredSlides={true}
        navigation={{
          nextEl: ".swiper-right",
          prevEl: ".swiper-left",
        }}
        breakpoints={{
          1920: { slidesPerView: 3, spaceBetween: 10 },
          1800: { slidesPerView: 4, spaceBetween: 10 },
          1600: { slidesPerView: 3, spaceBetween: 10 },
          1440: { slidesPerView: 3, spaceBetween: 5 },
          1280: { slidesPerView: 2, spaceBetween: 5 },
          1024: { slidesPerView: 2, spaceBetween: 10 },
          768: { slidesPerView: 2, spaceBetween: 5 },
          600: { slidesPerView: 1, spaceBetween: 0 },
          480: { slidesPerView: 1, spaceBetween: 0 },
          350: { slidesPerView: 1, spaceBetween: 0 },
          200: { slidesPerView: 1, spaceBetween: 0 },
        }}
      >
        {cvcard.map((cdata, index) => (
          <SwiperSlide key={index}>
            <div className="cv-card-div">
              <div className="cv-card card">
                <div className="card-img-div">
                  <img src={cdata.img} className="idea-img img-fluid" alt="idea" />
                </div>
                <h4 className="text-white">{cdata.title}</h4>
                <p className="cp-text-1">{cdata.content}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Buttons */}
      <div className="swiper-navigate">
        <div className="swiper-left">
          <img src={swiperleft} alt="previous" />
        </div>
        <div className="swiper-right">
          <img src={swiperright} alt="next" />
        </div>
      </div>
    </div>
  );
}

export default MobileSwiper;
