'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Autoplay, Navigation } from 'swiper/modules';
import IdeaBulb from '../../../assets/images/monkey1.svg';
import Monkey1 from '../../../assets/images/run1.svg';
import Monkey2 from '../../../assets/images/run2.svg';
import Monkey3 from '../../../assets/images/nft2.svg';
import Monkey4 from '../../../assets/images/nft3.svg';

import swiperleft from '../../../assets/images/swiperleft.svg';
import swiperright from '../../../assets/images/swiperright.svg';
import '../ApesSwiper/ApexSwiper.scss';
import { useRef } from 'react';
import MobileSwiper from '../Mobile Swiper/MobileSwiper';

const ApesSwiper = () => {
  const cvcard = [
    {
      title: 'Fun and Engaging',
      content: 'Say goodbye to boring earn models. With Apes Run, every shake brings you closer to exciting rewards.',
      img: IdeaBulb,
    },
    {
      title: 'Instant Gratification',
      content: 'Earn points or tokens in real-time and redeem them for real-world rewards or trade them within our ecosystem.',
      img: Monkey1,

    },
    {
      title: 'Easy Access',
      content: 'All you need is a smartphone and a Telegram account. Join the bot and start earning without any hassle.',
      img: Monkey2,

    },
    {
      title: 'Community and Competition',
      content: ' Engage in daily challenges, climb leaderboards, and share your progress with friends.',
      img: Monkey3,

    },
    {
      title: 'Secure and Fair',
      content: ' Advanced motion-sensing technology ensures accurate tracking, fair rewards, and a level playing field for everyone.',
      img: Monkey4,

    },
    // {
    //   title: 'Integrity',
    //   content: 'Conducting business with the highest ethical standards and transparency.',
    //   img: IdeaBulb,

    // },
    // {
    //   title: 'Integrity',
    //   content: 'Conducting business with the highest ethical standards and transparency.',
    //   img: Monkey2,
    // },
  ];

  const SwiperRef = useRef(null);

  // const openPdfInNewTab = () => {
  //   const pdfUrl = require("../../../assets/images/whitepaper.pdf");
  //   window.open(pdfUrl, "_blank");
  // };
  return (
    <div className='container'>
      <h4 className="topic-heading tw-pt-10">Why Apes Run?</h4>
{/* <div className='desktop-view'>

    <div className='cv-swiper home tw-py-20'>
       <div className='container'>
      <section className='home-section-2 pad-bottom-lg cv-section'>
        <div className='cv-card-section'>
          <Swiper
            onSwiper={(swiper) => (SwiperRef.current = swiper)} // Assign Swiper instance
            grabCursor={false}
            centeredSlides={false}
            slidesPerView={1}
            loop={true}
            allowTouchMove={false}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 35,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 35,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,

              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            pagination={{
              clickable: false,
            }}
            navigation={{
              nextEl: '.swiper-right',
              prevEl: '.swiper-left',
            }}
            modules={[EffectCoverflow, Autoplay, Navigation]}
            className='swiper'
          >
            {cvcard.map((cdata, index) => (
              <SwiperSlide key={index}>
                <div
                  className='cv-card card'
                  onMouseEnter={() => SwiperRef.current?.autoplay.stop()} // Pause autoplay on hover
                  onMouseLeave={() => SwiperRef.current?.autoplay.start()} // Resume autoplay on leave
                  onTouchStart={() => SwiperRef.current?.autoplay.stop()} // Pause autoplay on touch
                  onTouchEnd={() => SwiperRef.current?.autoplay.start()} // Resume autoplay on touch end
                >
                  <div className='card-img-div'>
                  <img
                    src={cdata.img}
                    width={296}
                    height={316}
                    className='idea-img img-fluid'
                    alt='idea-bulb'
                  />
                  </div>
                  <h4 className='text-white'>{cdata.title}</h4>
                  <p className='cp-text-1'>{cdata.content}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className='swiper-navigate'>
          <div className='swiper-left'>
            <img src={swiperleft} width={52} height={52} alt='swipernavi' />
          </div>
          <div className='swiper-right'>
            <img src={swiperright} width={52} height={52} alt='swipernavi' />
          </div>
        </div>
      </section>
      </div>
    </div>
    </div> */}
    {/* <div className='mobile-view'> */}
    <div className='swiper-div'>
   
<MobileSwiper/>

</div>
    {/* </div> */}
    {/* <div className='our-nft d-flex justify-content-center w-100'>
    <button className="see-more-btn" onClick={openPdfInNewTab}>WhitePaper</button>
    </div> */}
    </div>
  );
};

export default ApesSwiper;