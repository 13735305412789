
import React from 'react'
import VideoSlider from "../videoSlider";
import Buy from '../Common/How to Buy/Buy';


const HowToBuy = () => {
return(

<section
className=" tw-pt-14 md:tw-p-4  "
>
<Buy/>
</section>
)
};
export default HowToBuy;
