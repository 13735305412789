import React from 'react'
import metamask from '../../../assets/images/metamask.svg'
import trustwallet from '../../../assets/images/trustwallet.svg'
import walletconnect from '../../../assets/images/walletconnect.svg'
import exodus from '../../../assets/images/exodus.svg'
import safepal from '../../../assets/images/safepal.svg'
import '../Wallet Section/WalletSectionLogo.scss'
const WalletSection = () => {
  return (
    <div className='walllet-section tw-py-10 md:tw-py-20'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='desktop-view'>
            <div className='first-row'>
              <img src={metamask} width={261} height={79} alt='metamask' title='metamask-logo' className='wallet-option'/>
              <img src={trustwallet} width={261} height={79} alt='trustwallet' title='trustwallet-logo' className='wallet-option'/>
              <img src={walletconnect} width={261} height={79} alt='walletconnect' title='walletconnect-logo' className='wallet-option'/>
            </div>
            <div className='second-row'>
              <img src={exodus} width={261} height={79} alt='exodus' title='exodus-logo' className='wallet-option'/>
              <img src={safepal} width={261} height={79} alt='safepal' title='safepal-logo' className='wallet-option' />

            </div>
            </div>
            <div className='mobile-view'>
<div className='row'>
  <div className='col-6'>
  <img src={metamask} width={261} height={79} alt='metamask' title='metamask-logo' className='wallet-option'/>

  </div>
  <div className='col-6'>
  <img src={trustwallet} width={261} height={79} alt='trustwallet' title='trustwallet-logo' className='wallet-option'/>

</div>
</div>
<div className='row'>
  <div className='col-6'>
  <img src={walletconnect} width={261} height={79} alt='walletconnect' title='walletconnect-logo' className='wallet-option'/>


  </div>
  <div className='col-6'>
  <img src={exodus} width={261} height={79} alt='exodus' title='exodus-logo' className='wallet-option'/>


</div>
</div>
<div className='row'>
<div className='col-6 offset-3'>
<img src={safepal} width={261} height={79} alt='safepal' title='safepal-logo' className='wallet-option' />
</div>
</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default WalletSection
