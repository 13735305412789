import React from 'react'
import '../FAQ/FAQ.scss'
const FAQ = () => {
    return (
        <div className='faq'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='faq-head'>
                            <h4>Find answers to<br className='break' />
                                common questions.</h4>
                        </div>
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is Apes Run?
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p>Apes Run is an engaging mobile app game inspired by the mechanics of "Hamster." Players advance through levels by clicking, earning APS tokens that can be cashed out or used for in-game enhancements.</p>
      </div>
    </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    On which platforms is Apes Run available?
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body"><p>
                                    Apes Run is available on both iOS and Android devices, making it accessible to a wide range of users.</p></div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    How do players earn APS tokens?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Players earn APS tokens based on their performance and progress in the game. The more you play, the more tokens you can earn.
                                        </p>

                                    </div>
                                </div>
                            </div>


                            {/* <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    How do players earn APS tokens?
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Players earn APS tokens based on their performance and progress in the game. The more you play, the more tokens you can earn.
                                        </p>

                                    </div>
                                </div>
                            </div> */}



                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefo" aria-expanded="false" aria-controls="flush-collapsefo">
                                    Can APS tokens be converted to real money?
                                    </button>
                                </h2>
                                <div id="flush-collapsefo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Yes, APS tokens can be exchanged for real-world currency, including USDT (Tether), providing tangible value to your in-game achievements.
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefi" aria-expanded="false" aria-controls="flush-collapsefi">
                                    What are in-app purchases?
                                    </button>
                                </h2>
                                <div id="flush-collapsefi" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Players can purchase boosts to accelerate their token earnings. The app returns 90% of the expenditure back to players in APS tokens once the boost system is activated.
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                    How do APS token holders generate revenue?
                                    </button>
                                </h2>
                                <div id="flush-collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        More tokens used can increase the token price based on blockchain technology algorithms. Additionally, staking and an MLM system help boost sales and generate wealth for participants.
                                        </p>

                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                    What makes Apes Run appealing to players and long-term token holders?
                                    </button>
                                </h2>
                                <div id="flush-collapseseven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Apes Run combines gaming with blockchain technology, offering diverse revenue streams and the potential for significant growth. This provides an exciting opportunity for players and long-term token holders.
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                                    Is there a competitive aspect to the game?
                                    </button>
                                </h2>
                                <div id="flush-collapseeight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        Yes, players can compete globally on leaderboards, driving continuous engagement and fostering a sense of community.
                                        </p>

                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                                    How does Apes Run ensure scalability?
                                    </button>
                                </h2>
                                <div id="flush-collapsenine" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p>
                                        The app is built on a scalable platform, allowing it to accommodate rapid user growth and the introduction of new features and expansions.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ
