
import React, { useState } from 'react'

import ArtCard from '../NFT Card/ArtCard';
import '../OurNFT/NFTS.scss'
import monkey1 from '../../../assets/images/monkey1.svg';
import monkey2 from '../../../assets/images/nft2.svg';
import monkey3 from '../../../assets/images/nft3.svg';
import monkey4 from '../../../assets/images/nft4.svg';
import monkey5 from '../../../assets/images/nft5.svg';

const OurNFT = () => {
    const [activeTab, setActiveTab] = useState("Art");
     const [visibleCards, setVisibleCards] = useState(5); // Initially show 5 cards
    
      const handleSeeMore = () => {
        setVisibleCards((prev) => prev + 5); // Show 5 more cards on button click
      };
      const cardData = [
        {
          img: monkey1,
          title: "Monkart",
          subtitle: "Coolguyzz.io",
          eth: "1.5 ETH",
          usd: "$2045.12",
          floorPrice: "Floor Price",
          change: "-12.45%",
          isGain: false,
        },
        {
          img: monkey5,
          title: "Apeirox",
          subtitle: "Coolguyzz.io",
          eth: "2.3 ETH",
          usd: "$2045.12",
          floorPrice: "Floor Price",
          change: "-12.45%",
          isGain: false,
        },
        {
          img: monkey2,
          title: "Primatex",
          subtitle: "Coolguyzz.io",
          eth: "0.5 ETH",
          usd: "$2045.12",
          floorPrice: "Floor Price",
          change: "+12.45%",
          isGain: true,
        },
        {
          img: monkey3,
          title: "Banzoid",
          subtitle: "Coolguyzz.io",
          eth: "1.3 ETH",
          usd: "$2045.12",
          floorPrice: "Floor Price",
          change: "+12.45%",
          isGain: true,
        },
        {
          img: monkey4,
          title: "Chimpire",
          subtitle: "Coolguyzz.io",
          eth: "1.1 ETH",
          usd: "$2045.12",
          floorPrice: "Floor Price",
          change: "-12.45%",
          isGain: false,
        },
      
        
        // {
        //   img: monkey1,
        //   title: "COOLGUYZZ",
        //   subtitle: "Coolguyzz.io",
        //   eth: "1.5 ETH",
        //   usd: "$2045.12",
        //   floorPrice: "Floor Price",
        //   change: "-12.45%",
        //   isGain: false,
        // },
        // {
        //   img: monkey5,
        //   title: "COOLGUYZZ",
        //   subtitle: "Coolguyzz.io",
        //   eth: "2.3 ETH",
        //   usd: "$2045.12",
        //   floorPrice: "Floor Price",
        //   change: "-12.45%",
        //   isGain: false,
        // },
        // {
        //   img: monkey2,
        //   title: "COOLGUYZZ",
        //   subtitle: "Coolguyzz.io",
        //   eth: "0.5 ETH",
        //   usd: "$2045.12",
        //   floorPrice: "Floor Price",
        //   change: "+12.45%",
        //   isGain: true,
        // },
        // {
        //   img: monkey3,
        //   title: "COOLGUYZZ",
        //   subtitle: "Coolguyzz.io",
        //   eth: "1.3 ETH",
        //   usd: "$2045.12",
        //   floorPrice: "Floor Price",
        //   change: "+12.45%",
        //   isGain: true,
        // },
        // {
        //   img: monkey4,
        //   title: "COOLGUYZZ",
        //   subtitle: "Coolguyzz.io",
        //   eth: "1.1 ETH",
        //   usd: "$2045.12",
        //   floorPrice: "Floor Price",
        //   change: "-12.45%",
        //   isGain: false,
        // },
      
      ];

    // Content for each tab
    // const tabContent = {
    //     Art: <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    //     Collectibles: <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    //     Metaverse: <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    //     "Virtual Worlds": <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    //     Sports: <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    //     Music: <ArtCard  visibleCards={visibleCards} cardData={cardData}/>,
    // };


    
    return (
        <div className="our-nft">
            <div className='container'>
        <div className='row'>
          <div className='col'>
        <div className="nft-head">
            <h4>OUR NFTS</h4>
        </div>

        {/* Tabs */}
        {/* <div className='desktop-view'>
        <div className="tab-btn-div">
            {Object.keys(tabContent).map((tab) => (
                <button
                    key={tab}
                    className={`btn-tabs ${activeTab === tab ? "active-tab" : ""}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
        </div>

        <div className='mobile-view'>
        
            <div className="row">
            {Object.keys(tabContent).map((tab) => (
            <div className='col-6'>
                <button
                    key={tab}
                    className={`btn-tabs ${activeTab === tab ? "active-tab" : ""}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
                </div>
            ))}

        </div>
        </div> */}
        {/* Tab Content */}
        <div className="tab-content">
            {/* <p>{tabContent[activeTab]}</p> */}
            <ArtCard  visibleCards={visibleCards} cardData={cardData}/>
        </div>
        {/* {visibleCards < cardData.length && ( // Show "See More" only if there are more cards
          <div className="text-center mt-4">
            <button className="see-more-btn" onClick={handleSeeMore}>
              See More
            </button>
          </div>
        )} */}

       </div>
        </div>
      </div>
      </div>
    )
}

export default OurNFT
