import React, { useState } from 'react';

const ArtCard = ({visibleCards,cardData}) => {

  return (
    <div className="main-nft-card">
      <div className="container">
        <div className="row">
          <div className="col">

            <div className="nft-card">
              {cardData.slice(0, visibleCards).map((card, index) => (
                <div className="card" key={index}>
                  <img
                    src={card.img}
                    width={211}
                    height={208}
                    alt="monkey1 img"
                    title="monkey card img"
                    className="card-img-top"
                  />
                  <div className="card-body w-100">
                    <h5 className="card-title">{card.title}</h5>
                    {/* <p className="card-text">{card.subtitle}</p> */}
                    <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                      <div>
                        <h4>{card.eth}</h4>
                      </div>
                      <div>
                        <h4>{card.usd}</h4>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mt-1">
                      <div>
                        <p>{card.floorPrice}</p>
                      </div>
                      <div>
                        <p className={card.isGain ? "gain" : "loss"}>{card.change}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtCard;
