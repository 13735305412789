import React, { useState } from "react";
// import monkey from '../../assets/images/apesrunmonkey.svg'
// import primelogo from '../../assets/images/primelogo.svg'
// import ustdicon from '../../assets/images/ustdicon.svg'
// import logo from '../../assets/images/apesrunlogo.svg'

import "../Vision Mission/VissionMission.scss";
import Monkey from "../Monkey/Monkey";
import FormBanner from "../Form/FormBanner";
const VisionMission = ({ props }) => {
  const [isLive, setIsLive] = useState(true);

  // Function to toggle the state when button is clicked
  const toggleContent = () => {
    setIsLive((prevState) => !prevState); // Toggle between true and false
  };
  const timeData = [
    { value: 51, label: "Days" },
    { value: 5, label: "Hours" },
    { value: 11, label: "Minutes" },
    { value: 52, label: "Seconds" },
  ];

  return (
    <div className="vision-mission">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-lg-6">
                <div className="vission-mission-content">
                  <div className="vision-head ">
                    <h1>Our Vision </h1>
                    <p>
                      At APESRUN, we envision a dynamic gaming platform where
                      every shake leads to financial opportunity and community
                      engagement. We aim to create a trusted environment that
                      empowers players to maximize their earnings while enjoying
                      an entertaining experience, shaping a future where gaming
                      and wealth creation go hand in hand.
                    </p>
                  </div>
                  <div className="vision-head">
                    <h1>Our Mission</h1>
                    <p>
                      At APESRUN, our mission is to empower gamers to achieve
                      financial success while enjoying a fun and engaging gaming
                      experience. By allowing players to earn “APS” with every
                      click—redeemable for cash—we create opportunities for
                      gamers to build wealth and thrive, fostering a rewarding
                      future for our vibrant community.
                    </p>
                  </div>

                  <div className="banner-btns-div">
                    <button className="whitepaper-btn">Whitepaper</button>
                    <button className="get-btn" onClick={toggleContent}>
                      {isLive ? "Get APS" : "Back to home"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {isLive ? (
                  <Monkey props={props} />
                ) : (
                  <FormBanner props={props} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
